const HUB_ARROW_DIRECTIONS = {
    MY_KL_WW: 'right',
    MY_KL_TTDI: 'left',
    MY_KL_KAJANG: 'right',
    MY_KL_PRIMA_AVENUE: 'right',
    MY_KL_AEON_MALURI: 'right',
    MY_KL_SUNWAY_PUTRA: 'right',
    MY_KL_SELAYANG_MALL: 'right',
    MY_KL_SETIA_CITY_MALL: 'right',
    MY_KL_MENARA_HAP_SENG: 'right',
    MY_KL_MITSUI_LALAPORT: 'right',
    MY_KL_PARADIGM_MALL: 'right',
    MY_KL_IOI_CITY_MALL: 'right',
    MY_KL_QUAYSIDE_MALL: 'right',
    MY_KL_CRYSTAL_VILLE: 'right',
    MY_KL_SETIA_TAIPAN: 'right',
    MY_NSN_AEON_NILAI: 'right',
    MY_KL_KLIA2_ARRIVAL: 'right',
    MY_KL_SB_MALL: 'right',
    MY_KL_GIANT_13_SA: 'up',
    MY_KL_TAMAN_PELANGI_SEMENYIH: 'right',
    MY_KL_GIANT_PUTRA_HEIGHTS: 'right',
    MY_KL_BANTING: 'up',
    MY_KL_BANDAR_BARU_SELAYANG: 'up',
    MY_KL_HULU_LANGAT: 'up',
    MY_KL_PUCHONG_UTAMA: 'up',
    MY_MLK_SUNGAI_UDANG: 'up',
    MY_KL_CJ: 'right',
    MY_KL_BUKIT_SENTOSA: 'up',
    MY_KL_SUBANG_BESTARI: 'right'
};

const LUCKY_WHEEL_ADDONS_SKU_CODES = [
    // 'Banana Loaf Slice',
    // 'Mini Butrscotch Muff',
    // 'Mini Mocha Muffin',
    // 'Red Velvet Muffin',
    // 'Mini Choc Muff',
    // 'Red Velvet Cookie',
    // 'Honey Green Tea',
    // 'Lemonade',
    // 'Ice Americano',
    // 'Ice Coffee',
    // 'Lychee Green Tea',
    // 'Sirap Ros Lemonade',
    // 'Lychee Lemonade',
    // 'Mango Lemonade',
    // 'Strawberry Lemonade',
    // 'Ice Latte',
    // 'Ice Long Black',
    // 'Ice Caramel Latte',
    // 'Teh Ais Padu',
    'Buttermilk Salt Egg',
    'Creamy M&C',
    'Sweet & Sour Chicken',
    'Honey Soy Chic',
];

const PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS = {
    //key of this object: number of addons to show
    //value of a particular key of this object: is an object itself which has a key equal to number of image to be shown and value = xTransalte,Y,rotation
    8: {
        1: {
            xTranslate: 235,
            yTranslate: 300,
            rotate: 195,
        },
        2: {
            xTranslate: 320,
            yTranslate: 240,
            rotate: 256,
        },
        3: {
            xTranslate: 340,
            yTranslate: 90,
            rotate: 290,
        },
        4: {
            xTranslate: 242,
            yTranslate: 55,
            rotate: -15,
        },
        5: {
            xTranslate: 135,
            yTranslate: 25,
            rotate: 16,
        },
        6: {
            xTranslate: 50,
            yTranslate: 124,
            rotate: 75,
        },
        7: {
            xTranslate: 35,
            yTranslate: 230,
            rotate: 115,
        },
        8: {
            xTranslate: 129,
            yTranslate: 315,
            rotate: 162,
        },
    },
    6: {
        1: {
            xTranslate: 173,
            yTranslate: 315,
            rotate: 170,
        },
        2: {
            xTranslate: 293,
            yTranslate: 243,
            rotate: 247,
        },
        3: {
            xTranslate: 325,
            yTranslate: 80,
            rotate: 295,
        },
        4: {
            xTranslate: 189,
            yTranslate: 55,
            rotate: 12,
        },
        5: {
            xTranslate: 55,
            yTranslate: 85,
            rotate: 50,
        },
        6: {
            xTranslate: 70,
            yTranslate: 245,
            rotate: 135,
        },
    },
    4: {
        1: {
            xTranslate: 60,
            yTranslate: 265,
            rotate: 130,
        },
        2: {
            xTranslate: 280,
            yTranslate: 265,
            rotate: 220,
        },
        3: {
            xTranslate: 285,
            yTranslate: 60,
            rotate: 320,
        },
        4: {
            xTranslate: 90,
            yTranslate: 70,
            rotate: 40,
        },
    },
    2: {
        1: {
            xTranslate: 180,
            yTranslate: 300,
            rotate: 180,
        },
        2: {
            xTranslate: 185,
            yTranslate: 0,
            rotate: 0,
        },
    }
};


const PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS = {
    //key of this object: number of addons to show
    //value of a particular key of this object: is an object itself which has a key equal to number of image to be shown and value = xTransalte,Y,rotation
    8: {
        1: {
            xTranslate: 255,
            yTranslate: 335,
            rotate: 195,
        },
        2: {
            xTranslate: 320,
            yTranslate: 240,
            rotate: 256,
        },
        3: {
            xTranslate: 360,
            yTranslate: 75,
            rotate: 290,
        },
        4: {
            xTranslate: 242,
            yTranslate: 55,
            rotate: -15,
        },
        5: {
            xTranslate: 118,
            yTranslate: -25,
            rotate: 16,
        },
        6: {
            xTranslate: 50,
            yTranslate: 124,
            rotate: 75,
        },
        7: {
            xTranslate: 8,
            yTranslate: 220,
            rotate: 115,
        },
        8: {
            xTranslate: 129,
            yTranslate: 315,
            rotate: 162,
        },
    },
    6: {
        1: {
            xTranslate: 173,
            yTranslate: 315,
            rotate: 170,
        },
        2: {
            xTranslate: 293,
            yTranslate: 243,
            rotate: 247,
        },
        3: {
            xTranslate: 335,
            yTranslate: 60,
            rotate: 295,
        },
        4: {
            xTranslate: 189,
            yTranslate: 55,
            rotate: 12,
        },
        5: {
            xTranslate: 35,
            yTranslate: 60,
            rotate: 50,
        },
        6: {
            xTranslate: 70,
            yTranslate: 245,
            rotate: 135,
        },
    },
    4: {
        1: {
            xTranslate: 60,
            yTranslate: 265,
            rotate: 130,
        },
        2: {
            xTranslate: 280,
            yTranslate: 265,
            rotate: 220,
        },
        3: {
            xTranslate: 315,
            yTranslate: 30,
            rotate: 320,
        },
        4: {
            xTranslate: 90,
            yTranslate: 70,
            rotate: 40,
        },
    },
    2: {
        1: {
            xTranslate: 180,
            yTranslate: 300,
            rotate: 180,
        },
        2: {
            xTranslate: 185,
            yTranslate: 0,
            rotate: 0,
        },
    }
};


const PIE_TRANSLATION_ROTATION_VALUES = {
    //key of this object: number of addons to show
    //value of a particular key of this object: is an object itself which has a key equal to number of image to be shown and value = xTransalte,Y,rotation
    10: {
        1: {
            xTranslate: 380,
            yTranslate: 217,
            rotate: 242,
        },
        2: {
            xTranslate: 328,
            yTranslate: 137,
            rotate: 302,
        },
        3: {
            xTranslate: 305,
            yTranslate: 6,
            rotate: 313,
        },
        4: {
            xTranslate: 188,
            yTranslate: 30,
            rotate: 12,
        },
        5: {
            xTranslate: 65,
            yTranslate: -24,
            rotate: 33,
        },
        6: {
            xTranslate: 90,
            yTranslate: 295,
            rotate: 150,
        },
        7: {
            xTranslate: 185,
            yTranslate: 315,
            rotate: 180,
        },
        8: {
            xTranslate: 270,
            yTranslate: 300,
            rotate: 230,
        },
        9: {
            xTranslate: 40,
            yTranslate: 215,
            rotate: 110,
        },
        10: {
            xTranslate: 42,
            yTranslate: 126,
            rotate: 82,
        },
    },
    8: {
        1: {
            xTranslate: 255,
            yTranslate: 335,
            rotate: 195,
        },
        2: {
            xTranslate: 320,
            yTranslate: 240,
            rotate: 256,
        },
        3: {
            xTranslate: 340,
            yTranslate: 90,
            rotate: 290,
        },
        4: {
            xTranslate: 242,
            yTranslate: 55,
            rotate: -15,
        },
        5: {
            xTranslate: 130,
            yTranslate: 0,
            rotate: 16,
        },
        6: {
            xTranslate: 50,
            yTranslate: 124,
            rotate: 75,
        },
        7: {
            xTranslate: 8,
            yTranslate: 220,
            rotate: 115,
        },
        8: {
            xTranslate: 129,
            yTranslate: 315,
            rotate: 162,
        },
    },
    6: {
        1: {
            xTranslate: 173,
            yTranslate: 315,
            rotate: 170,
        },
        2: {
            xTranslate: 293,
            yTranslate: 243,
            rotate: 247,
        },
        3: {
            xTranslate: 325,
            yTranslate: 80,
            rotate: 295,
        },
        4: {
            xTranslate: 189,
            yTranslate: 55,
            rotate: 12,
        },
        5: {
            xTranslate: 35,
            yTranslate: 60,
            rotate: 50,
        },
        6: {
            xTranslate: 70,
            yTranslate: 245,
            rotate: 135,
        },
    },
    5: {
        1: {
            xTranslate: 40,
            yTranslate: 250,
            rotate: 126,
        },
        2: {
            xTranslate: 230,
            yTranslate: 300,
            rotate: 200,
        },
        3: {
            xTranslate: 355,
            yTranslate: 150,
            rotate: 270,
        },
        4: {
            xTranslate: 230,
            yTranslate: 30,
            rotate: -17,
        },
        5: {
            xTranslate: 70,
            yTranslate: 90,
            rotate: 52,
        },
    },
    4: {
        1: {
            xTranslate: 60,
            yTranslate: 265,
            rotate: 130,
        },
        2: {
            xTranslate: 280,
            yTranslate: 265,
            rotate: 220,
        },
        3: {
            xTranslate: 315,
            yTranslate: 30,
            rotate: 320,
        },
        4: {
            xTranslate: 90,
            yTranslate: 70,
            rotate: 40,
        },
    },
    3: {
        1: {
            xTranslate: 100,
            yTranslate: 3,
            rotate: 20,
        },
        2: {
            xTranslate: 105,
            yTranslate: 295,
            rotate: 150,
        },
        3: {
            xTranslate: 360,
            yTranslate: 160,
            rotate: 250,
        },
    },
    2: {
        1: {
            xTranslate: 180,
            yTranslate: 300,
            rotate: 180,
        },
        2: {
            xTranslate: 165,
            yTranslate:75,
            rotate: 12,
        },
    },
    1: {
        1: {
            xTranslate: -10,
            yTranslate: 100,
            rotate: 180,
        },
    },
};

export default {
    LUCKY_WHEEL_ADDONS_SKU_CODES,
    PIE_TRANSLATION_ROTATION_VALUES,
    PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS,
    PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS,
    HUB_ARROW_DIRECTIONS,
};
