import { action, computed, observable, when } from 'mobx';
import Bacon from 'baconjs';
import get from 'lodash/get';
import XDate from 'xdate';
import Cookies from 'js-cookie';

import phoneNumberValidator from './validators/phoneNumberValidator';
import { ValidationResult } from '../services/validator';
import { fromTimestamp, isToday, secondsInDay } from '../services/dateTime';
import { MAYBE, RESOURCE } from '../services/types';
import CartServices from '../store/services/cartServices';
import { services } from '../services/index';
import menuHeaderStore from '../store/menuHeaderStore';
import terminalCartStore from './terminalCartStore';
import terminalDishStore from '../store/terminalDishStore';
import terminalAuthStore from '../store/terminalAuthStore';
import { parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile';
import { saveLangToStorage } from '../services/translation';
import terminalConstants from '../constants/terminal';

class TerminalMenuStore {
    POPUP_ADDON_ITEMS = 'PopupAddonItems';
    POPUP_SNACK_DESSERT_ITEMS = 'PopupSnackDessertItems';
    POPUP_PHONE_NUMBER = 'PopupPhoneNumber';
    // POPUP_TABLE_NUMBER = 'PopupTableNumber';
    POPUP_CONFIRM_ORDER = 'PopupConfirmOrder';
    POPUP_OTP_ORDER = 'PopupOTPOrder';
    POPUP_ORDERING = 'PopupOrdering';
    POPUP_PAYMENT_OPTION = 'PaymentOption';
    POPUP_QRCODE = 'PopupQrCode';
    POPUP_TAP_CARD = 'PopupTapCard';
    POPUP_PAYMENT_CONFIRM = 'PopupPaymentConfirm';
    POPUP_PAYMENT_FAILURE = 'PopupPaymentFailure';

    KIOSK_RANDOM_ID = Math.floor(Math.random() * 9999);

    HUB_ARROW_DIRECTIONS = terminalConstants.HUB_ARROW_DIRECTIONS;

    itemTypes = {
        MAINS: 'Mains',
        DEALS: 'Deals',
        SNACKS: 'Snacks',
        DESSERTS: 'Desserts',
        DRINKS: 'Drinks',
        COUPONS: 'Coupons',
        DEAL_SELECTION: 'DealSelection',
    };
    ORDER_TYPES = {
        DINE_IN: 'DINE_IN',
        TAKE_AWAY: 'TAKE_AWAY',
    };
    INTERNATIONAL_PHONE_HUB_CODES = ['MY_KL_KLIA2_ARRIVAL', 'MY_KL_KLIA2_DEPARTURE'];
    itemTypesSeries = Object.values(this.itemTypes);

    //variables for mobile kiosk
    addonTypes = {
        SNACKS: 'Snacks',
        DRINKS: 'Drinks',
    };

    mobileScreens = {
        HOME: 'OrderTypeScreen',
        ITEM: 'AllItemsHomeScreen',
        ADDON: 'AddonsScreen',
        CART: 'CartScreen',
        HELP: 'HelpScreen',
        ORDER_CONFIRMATION: 'OrderConfirmationScreen',
    };

    LUCKY_WHEEL_ADDONS_SKU_CODES = terminalConstants.LUCKY_WHEEL_ADDONS_SKU_CODES;
    PIE_TRANSLATION_ROTATION_VALUES = terminalConstants.PIE_TRANSLATION_ROTATION_VALUES;
    PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS = terminalConstants.PIE_TRANSLATION_ROTATION_VALUES_FOR_ITEMS;
    PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS = terminalConstants.PIE_TRANSLATION_ROTATION_VALUES_FOR_DRINKS;
    MAX_LUCKY_WHEEL_ADDONS = 4;
    @observable.ref itemsFetcher = RESOURCE.Initial;
    @observable.ref sectionsFetcher = RESOURCE.Initial;
    @observable.ref addonsFetcher = RESOURCE.Initial;
    @observable.ref dishFetcher = RESOURCE.Initial;
    @observable.ref mealDetail = MAYBE.None;
    @observable.ref terminalInfo = MAYBE.None;
    @observable.ref orderInfo = MAYBE.None;
    @observable.ref phoneValidationResult = new ValidationResult();
    @observable phoneValidationMessage = '';
    @observable menuListToday = [];
    @observable magicSignInLink = '';
    @observable currentMenuList = {};

    @observable itemsUIView = this.itemTypes.MAINS;
    @observable showSplashScreen = true;
    @observable couponErrMessage = false;
    @observable couponDisable = false;
    @observable couponTxt = '';

    @observable isShowingAddon = false;
    @observable isShowingSummary = false;
    @observable arrowDirection = 'left';
    @observable hubCode = '';

    @observable orderType = null; //possible values: DINE_IN, TAKE_AWAY
    @observable tableNumber = ''; //possible values: '', 'a number'
    @observable dessertAndSnackItems = [];
    @observable firstUpsellItemId = null;
    @observable secondUpsellItemId = null;
    @observable thirdUpsellItemId = null;

    //combo related state data
    @observable combo = null;
    @observable isComboPresent = false;
    @observable drinkComboItems = [];
    @observable cardReaderStatus = false;
    @observable cardReaderCheckInterval = null;
    @observable cheapestAddonItemPrice = 0;

    @observable showingPopup = '';
    @observable previousPopup = '';
    @observable otpField = '';

    @observable phoneNumber = {
        countryCode: '60',
        number: '',
    };
    @observable rawPhoneNumber = '';
    @observable askForName = false;
    @observable customerName = '';
    @observable loadingPhoneNoExist = false; //loading to be shown on phone number popup
    @observable customerId = null;

    @observable currentDealCombo = '';

    //lucky wheel related variables
    @observable addonsToShowOnLuckyWheel = null;
    @observable showLuckyWheelScreen = false;
    @observable showLuckyWheel = true;
    @observable luckyWheelReward = null;
    @observable eligibleForLuckyWheelAfterTableNum = false;
    @observable isSkipClicked = false;

    @observable bearerToken = null;

    @observable walletStatus = false;

    @observable otpValidationMessage = '';
    @observable couponValidation = null;
    @observable couponValidationMsg = '';
    @observable pinValidation = null;
    @observable paymentType = 'Pos';
    @observable qrCodeText = '';
    @observable itemSelected = '';

    @observable otpToken = '';
    @observable orderNumber = '';
    @observable pendingAmount = null;
    @observable stripeLocationId = '';
    @observable paymentStatus = false;
    @observable backBtn = false;
    @observable dealComboList = [];
    @observable comboObject = '';
    @observable totalComboList = '';
    @observable totalMenuList = [];
    @observable cartItem = '';

    onNeededToShowAddon$ = new Bacon.Bus();

    //variables for mobile kiosk
    @observable addonsUIView = 'Snacks';
    @observable showMobileScreen = this.mobileScreens.HOME;
    @observable previouslyOpenedMobileScreen = this.showMobileScreen;
    @observable yPositionToScroll = null; //variable used to scroll on items home screen on mobile when clicked on view all button
    @observable sections = [];
    constructor() {
        this.queryStartDate = new XDate();
        this.queryEndDate = new XDate();
    }

    @computed get menuDate() {
        return get(this.firstItem, ['data', 'date'], '');
    }

    @computed get timeSlotString() {
        return get(this.terminalInfo, ['data', 'timeframe'], '');
    }

    @computed get addressId() {
        return get(this.terminalInfo, ['data', 'address', 'id'], '');
    }

    @computed get netTotal() {
        return get(this.orderInfo, ['data', 'net_total'], 0);
    }

    @computed get username() {
        return get(this.terminalInfo, ['data', 'username'], '');
    }

    @computed get address() {
        return get(this.terminalInfo, ['data', 'address', 'display_address']);
    }

    @computed get address_street() {
        return get(this.terminalInfo, ['data', 'address', 'street']);
    }

    @computed get address_postcode() {
        return get(this.terminalInfo, ['data', 'address', 'postal_code']);
    }

    @computed get address_city() {
        return get(this.terminalInfo, ['data', 'address', 'city']);
    }

    @computed get address_building() {
        return get(this.terminalInfo, ['data', 'address', 'building_name']);
    }

    @computed get terminalWithNoAddOns() {
        return (
            this.terminalInfo.data.id === '5cc7a98c89ed760cf681d432' ||
            this.terminalInfo.data.id === '5b56cd117650bc11e07f41b5' ||
            this.terminalInfo.data.id === '5cc7cdd92839820d022a3c05'
        );
    }

    @computed get paymentMethod() {
        const rawType = get(this.orderInfo, ['data', 'payment_method'], 'Cash');

        switch (rawType) {
            case 'Cash':
                return 'Cash on Delivery';
            default:
                return rawType;
        }
    }

    @computed get firstItem() {
        if (RESOURCE.Success.is(this.itemsFetcher)) {
            const items = this.itemsFetcher.data;
            if (items[0]) {
                return MAYBE.Some(items[0]);
            }
        }

        return MAYBE.None;
    }

    hasPassedTime(moment) {
        const momentInSeconds = secondsInDay(fromTimestamp(moment));
        const currentMomentInSeconds = secondsInDay(fromTimestamp(new Date().getTime()));

        return currentMomentInSeconds > momentInSeconds;
    }

    @action setShowMobileScreen = (mobileScreen) => {
        this.previouslyOpenedMobileScreen = this.showMobileScreen;
        this.showMobileScreen = mobileScreen;
    };

    @action setItemsUIView = (itemsUIView) => {
        this.itemsUIView = itemsUIView;
    };

    @action setCouponDisable = (disable) => {
        this.couponDisable = disable;
    };

    @action setCouponErrMessage = (msg) => {
        this.couponErrMessage = msg;
    };

    @action setCouponTxt = (txt) => {
        this.couponTxt = txt;
    };

    @action setAddonsView = (addonsUIView) => {
        this.addonsUIView = addonsUIView;
    };
    @action setYPositionToScroll = (yPositionToScroll) => {
        this.yPositionToScroll = yPositionToScroll;
    };

    @action setSplashScreen = (splashScreenState) => {
        this.showSplashScreen = splashScreenState;
    };

    @action setLuckyWheelReward = (rewardItem) => {
        this.luckyWheelReward = rewardItem;
    };
    @action setShowLuckyWheel = (state) => {
        this.showLuckyWheel = state;
    };

    getLanguage = () => {
        return localStorage.getItem('lang') || 'ms';
    };

    setLanguage = (language) => {
        saveLangToStorage(language);
        services.api.SetLanguage(language);
        window.location.href = `/terminal`;
    };

    @action setOrderTypeIfExistsAndReloadMenu = (orderType) => {
        if (!orderType) {
            this.itemsFetcher = RESOURCE.Failure;
            return;
        }
        this.setOrderType(orderType);
        this.setSplashScreen(false);
        this.fetchTerminalInfo().then(() => this.fetchMenu());
    };

    @action setItemSelected = (itemselected) => {
        this.itemSelected = itemselected;
    };

    @action showSplashScreenActions = (orderType) => {
        this.setSplashScreen(!orderType);
        this.showLuckyWheelScreen = false;
        this.couponTxt = '';
        this.couponDisable = false;
        this.couponErrMessage = null;
        this.phoneValidationMessage = null;
        //reset the information
        this.emptyCart();
        this.refreshMenu();
        this.dealComboList = null;
        this.comboObject = null;
        this.totalComboList = '';
        this.totalMenuList = [];
        this.luckyWheelReward = null;
        terminalCartStore.checkValidation = false;
        this.orderNumber = '';
        this.qrCodeText = '';
        this.customerId = null;
        this.setOrderType(orderType);
        this.tableNumber = '';
        this.itemsUIView = this.itemTypes.MAINS;
        this.eligibleForLuckyWheelAfterTableNum = false;
        this.backBtn = false;
        terminalCartStore.loadItems([]);
        if (this.getLanguage() !== 'ms') {
            //set the language to ms, as this should be the default lang for terminal
            this.setLanguage('ms');
        }
    };

    @action bearerTokenActions(bearerToken) {
        this.bearerToken = bearerToken;
        services.api.AccessTokenStorage.setToken(bearerToken);
        terminalAuthStore.authed = true;
        services.api.GetTerminalInfo(bearerToken).then((response) => {
            localStorage.setItem('terminalUserName', response.username);
            localStorage.setItem('stripeLocationId', response.stripe_location_id);
        });
    }

    @action setCardProvider(cardProvider) {
        localStorage.setItem('cardProvider', cardProvider);
    }

    @action getCardProvider() {
        return localStorage.getItem('cardProvider');
    }

    @action getDealComboOptions(items) {
        return [];
        // return services.api
        //     .GetDealComboOption({
        //         bearerToken: this.bearerToken,
        //         orderType: this.orderType,
        //         hubCode: this.hubCode,
        //     })
        //     .then((response) => {
        //         let data = this.itemsFetcher.data.concat(response);
        //         this.itemsFetcher.data = data;

        //         return response;
        //     });
    }

    @action rewardRedeemCode = (sku) => {
        const customerId = this.customerId;

        services.api.RewardRedeemCode({
            user_id: customerId,
            code: `${sku.toUpperCase()}WHEEL`,
        });
    };

    @action walletStatusCheck() {
        services.api.EwalletStatusCheck({ bearerToken: this.bearerToken }).then((response) => {
            this.walletStatus = response.available;
        });
    }

    @action fetchMenu() {
        this.itemsFetcher = RESOURCE.Loading;
        this.sectionsFetcher = RESOURCE.Loading;
        const terminalData = this.terminalInfo && this.terminalInfo.data;
        const startDateString = new Date().toLocaleDateString('es-CL', {
            timeZone: 'Asia/Kuala_Lumpur',
        });
        const endDateString = new Date().toLocaleDateString('es-CL', {
            timeZone: 'Asia/Kuala_Lumpur',
        });
        const orderMode = this.orderType
            ? this.orderType === this.ORDER_TYPES.DINE_IN
                ? 'DINE_IN'
                : 'PICK_UP'
            : 'DINE_IN';
        
        services.api.InvalidateInCartOrder();
        return services.api
            .GetTerminalMenuForDateRange({
                startDateString: startDateString,
                endDateString: endDateString,
                customAddress: (terminalData && terminalData.address.id) || null,
                bearerToken: this.bearerToken,
                orderMode: orderMode,
            })
            .then((response) => {
                when(
                    () => this.terminalInfo.data,
                    () => {
                        if (response && response.sections) {
                            this.sections = response.sections;
                            this.sections.sort((a, b) => a.order - b.order);
                            response.dates[0].items.forEach((item) => {
                                item.isValueMealOption = false;
                            });
                            const items = response.dates[0].items;
                            const combos = response.dates[0].mix_and_match_combos;
                            items.sort((a, b) => a.current_ranking - b.current_ranking);
                            const filteredItems = items.filter((item) => !item.invisible);
                            this.sections.forEach(section => {
                                if (section.type == "Category") {
                                    section.items = [];
                                    if (section.for_mix_and_match_combo) {
                                        section.mix_and_match_combos = [];
                                        if (combos) {
                                            combos.forEach(combo => {
                                                if (combo.menu_item_line_ids.includes(section.id)) {
                                                    // Push the matching item into the section's items array
                                                    section.mix_and_match_combos.push(combo);
                                                }
                                            })
                                        }
                                    } else {
                                        filteredItems.forEach(item => {
                                            if (item.menu_item_line_ids.includes(section.id)) {
                                                // Push the matching item into the section's items array
                                                section.items.push(item);
                                            }
                                        })
                                    }
                                }
                            })
                            this.sectionsFetcher = RESOURCE.Success(this.sections);
                            this.itemsFetcher = RESOURCE.Success(filteredItems);
                            this.drinkComboItems = filteredItems
                                .filter((item) => item.addon_category == 'Drinks' && item.value_meal_price != null)
                                .map((item) => ({
                                    ...item,
                                    menu_date: startDateString,
                                    date: this.menuListToday.date,
                                    isValueMealOption: true,
                                }));
                            this.isComboPresent = this.drinkComboItems.length > 0;
                            this.fetchAddons();
                            this.fetchSnacksAndDrinks();
                            this.getDealComboOptions(this.itemsFetcher);
                            this.addonsToShowOnLuckyWheel = filteredItems
                                .filter((item) => item.quantity_left > 0)
                                // .filter((item) => item.item_type === 'Addons')
                                .filter((item) => this.LUCKY_WHEEL_ADDONS_SKU_CODES.includes(item.sku))
                                .filter(
                                    (item) =>
                                        !item.title_bold.toLowerCase().includes('combo') &&
                                        !item.title_bold.toLowerCase().includes('cutlery')
                                )
                                .sort(() => 0.5 - Math.random()) //random shuffling
                                .slice(0, this.MAX_LUCKY_WHEEL_ADDONS);
                        } else {
                            this.itemsFetcher = RESOURCE.Failure(response.message, response);
                        }
                    }
                );
            })
            .catch((response) => {
                services.bannerEvents.showError(response.message);
                this.itemsFetcher = RESOURCE.Failure(response.message, response);
                this.isComboPresent = false;
            });
    }

    toggleMenuType = (isTomorrow) => {
        this.currentMenuList = this[`menuList${isTomorrow ? 'Tomorrow' : 'Today'}`];
        this.itemsFetcher = RESOURCE.Success(this.currentMenuList.menu_items);
    };

    hasMenuFor(tomorrow) {
        let selectedMealTime;

        if (tomorrow) {
            selectedMealTime = this.menuListTomorrow;
        } else {
            selectedMealTime = this.menuListToday;
        }

        if (selectedMealTime && selectedMealTime.any_meals_left) {
            const isItToday = isToday(selectedMealTime.menu_date);

            if (isItToday) {
                return !selectedMealTime.cut_off_reached;
            }
            return true;
        } else {
            return false;
        }
    }

    handleAddItemToCart = (item, isAddonItem) => {
        const { isComboPresent, POPUP_ADDON_ITEMS } = this;
        // const isComboItem = item.item_type === 'Combo';
        // if (isComboPresent && !isAddonItem && !isComboItem) {
        //     this.setShowPopup(POPUP_ADDON_ITEMS);
        // }
        terminalCartStore.addToCart(item);

        terminalCartStore.getTotalPrice();
    };

    handleAddCouponToCart = () => {
        terminalCartStore.addToCart(item);
    };

    @action clearCustomerInfo() {
        this.phoneNumber = { countryCode: '60', number: '' };
        if (this.isInternationalPhoneHubCode()) {
            this.rawPhoneNumber = '+60';
        } else {
            this.rawPhoneNumber = '';
        }
        this.customerName = '';
        this.pinValidation = false;
        this.order_type = null;
        this.askForName = false;
    }

    @action removeNameInfo() {
        this.askForName = false;
    }

    @action refreshMenu() {
        this.showingPopup = '';
        this.previousPopup = '';
        this.isShowingAddon = false;

        this.fetchMenu()
            .then(() => {
                this.clearCustomerInfo();
                this.phoneValidationResult = new ValidationResult();
                this.orderInfo = MAYBE.None;
                this.addonsFetcher = RESOURCE.Initial;
            })
            .catch(() => {
                services.bannerEvents.showError('There seems to be an error with the internet connection');
            });
    }

    @action fetchTerminalInfo() {
        return services.api
            .GetTerminalInfo({ bearerToken: this.bearerToken })
            .then((info) => {
                const hubCode = info.address && info.address.hub_code.toUpperCase();
                this.hubCode = hubCode;

                this.walletStatusCheck();

                this.arrowDirection = this.HUB_ARROW_DIRECTIONS[hubCode] ? this.HUB_ARROW_DIRECTIONS[hubCode] : 'left';

                if (!Cookies.get('terminal_user')) {
                    Cookies.set('terminal_user', true);
                }
                this.terminalInfo = MAYBE.Some(info);
                if (get(info, ['address', 'id'], false) === false) {
                    window.location.href = '/terminal-address';
                }
            })
            .catch((response) => {
                if (response.message === 'access denied') {
                    menuHeaderStore.logOut();
                } else {
                    services.bannerEvents.showError(response.message);
                }
            });
    }

    @action fetchAddons() {
        if (RESOURCE.Success.is(this.itemsFetcher)) {
            this.addonsFetcher = RESOURCE.Success(this.itemsFetcher.data);
        } else {
            this.addonsFetcher = RESOURCE.Failure('item fetcher not successful', {});
        }
    }

    @action fetchSnacksAndDrinks() {
        services.api.GetAddonsForUpsell({
            bearerToken: this.bearerToken,
            type: "MENU",
            subType: "KIOSK_ADDON_UPSELL_RANKING"
        }).then((response) => {
            for (let i = 0; i < response.length; i++){
                if (response[i].value == 1) {
                    this.firstUpsellItemId = response[i].key;
                } else if (response[i].value == 2) {
                    this.secondUpsellItemId = response[i].key;
                } else {
                    this.thirdUpsellItemId = response[i].key;
                }
            }
            this.fetchUpsellItem()
        });
    }

    @action fetchUpsellItem() {
        this.dessertAndSnackItems = [];
        let items = this.getAddonByCategory('Snacks').concat(this.getAddonByCategory('Desserts'));
        items.sort((a, b) => (a.current_ranking > b.current_ranking ? 1 : -1));
        let maxLength = 3;
        if (items.length < 3) {
            maxLength = items.length;
        }
        for (let i = 0; i < maxLength; i++) {
            this.dessertAndSnackItems.push(items[i]);
        }
        this.dessertAndSnackItems = this.dessertAndSnackItems.sort((a, b) => (a.price > b.price ? 1 : -1));

        let firstUpsellItem = items.filter((item) => item.menu_item_id == this.firstUpsellItemId)[0]
        let secondUpsellItem = items.filter((item) => item.menu_item_id == this.secondUpsellItemId)[0]
        let thirdUpsellItem = items.filter((item) => item.menu_item_id == this.thirdUpsellItemId)[0]
        
        if (firstUpsellItem) {
            let index = this.dessertAndSnackItems.findIndex(item => item.menu_item_id == this.firstUpsellItemId);
            if(index != -1){
                this.dessertAndSnackItems.splice(index, 1)
            }
        }

        if (secondUpsellItem) {
            let index = this.dessertAndSnackItems.findIndex(item => item.menu_item_id == this.secondUpsellItemId);
            if(index != -1){
                this.dessertAndSnackItems.splice(index, 1)
            }
        }

        if (thirdUpsellItem) {
            let index = this.dessertAndSnackItems.findIndex(item => item.menu_item_id == this.thirdUpsellItemId);
            if(index != -1){
                this.dessertAndSnackItems.splice(index, 1)
            }
        }

        if (firstUpsellItem) {
            this.dessertAndSnackItems.splice(0, 0, firstUpsellItem)
        }

        if (secondUpsellItem) {
            this.dessertAndSnackItems.splice(1, 0, secondUpsellItem)
        }

        if (thirdUpsellItem) {
            this.dessertAndSnackItems.splice(2, 0, thirdUpsellItem)
        }

        if (this.dessertAndSnackItems.length > 3) {
            this.dessertAndSnackItems.splice(3, this.dessertAndSnackItems.length)
        }
    }

    @action fetchDish(id) {
        this.dishFetcher = RESOURCE.Loading;

        services.api
            .GetTerminalDish({ id })
            .then((dish) => {
                terminalDishStore.dish = dish;
                this.dishFetcher = RESOURCE.Success(dish);
            })
            .catch(() => {
                this.dishFetcher = RESOURCE.Initial;
            });
    }

    @action hideDishDetail = () => {
        this.dishFetcher = RESOURCE.Initial;
    };

    @action setShowAddon(isShowingAddon) {
        this.isShowingAddon = isShowingAddon;
    }

    @action setShowSummary(isShowingSummary) {
        this.isShowingSummary = isShowingSummary;
    }

    @action setShowPopup(showingPopup) {
        this.showingPopup = showingPopup;
    }

    @action updatePhoneNumber(e) {
        this.phoneNumber[e.currentTarget.name] = e.currentTarget.value;
    }

    @action updateCountryCode(e) {
        this.phoneNumber[e.currentTarget.name] = e.currentTarget.value;
    }

    @action updateTableNumber(e) {
        this.tableNumber = e.currentTarget.value;
    }

    @action updateName(e) {
        this.customerName = e.currentTarget.value;
    }

    @action resendOTP() {
        if (this.phoneNumber.number[0] === '0') {
            this.phoneNumber.number = this.phoneNumber.number.substring(1);
        }
        const requestBody = {
            phone_no: this.getPhoneNumber(true),
        };
        services.api
            .ResendOTP({ body: requestBody })
            .then((response) => {})
            .catch((response) => {});
    }

    @action submitQRPayment = (isMobileKioskOrder) => {
        const isNewUser = this.askForName;
        const eligibleForLuckyWheel = this.eligibleForLuckyWheelAfterTableNum;
        this.confirmOrder(isMobileKioskOrder, isNewUser, eligibleForLuckyWheel);
    };

    @action cashReorder = () => {
        this.showingPopup = this.POPUP_ORDERING;

        services.api.CashPaymentReorder({ orderNumber: this.orderNumber }).then((response) => {
            this.backBtn = false;
            if (
                this.addonsToShowOnLuckyWheel &&
                this.addonsToShowOnLuckyWheel.length &&
                this.eligibleForLuckyWheelAfterTableNum &&
                this.paymentType == 'Pos'
            ) {
                //only trigger lucky wheel flow if there are addons to show on lucky wheel are present and user hasn't placed an order on app yet or it's new user
                if (this.addonsToShowOnLuckyWheel.length === 1) {
                    // if there's only one addon, skip the wheel and only show final screen
                    this.luckyWheelReward = this.addonsToShowOnLuckyWheel[0];
                    this.showLuckyWheelScreen = true;
                    this.showLuckyWheel = false;
                    this.rewardRedeemCode(this.luckyWheelReward.sku);
                } else {
                    this.showLuckyWheelScreen = true;
                    this.showLuckyWheel = true;
                }
            } else {
                this.showingPopup = this.POPUP_CONFIRM_ORDER;
            }
            this.emptyCart();
        });
    };

    @action validateCoupon = () => {
        if (this.phoneNumber.number[0] === '0') {
            this.phoneNumber.number = this.phoneNumber.number.substring(1);
        }
        const requestBody = {
            phone_no: this.getPhoneNumber(false),
            reward_code: this.couponDisable ? this.couponTxt : '',
        };

        services.api
            .ValidateCoupon({ body: requestBody })
            .then((response) => {
                this.couponValidation = true;
                this.pinValidation = true;
            })
            .catch((response) => {
                this.couponValidation = false;
                this.couponValidationMsg = response.message;
                this.setItemsUIView(this.itemTypes.MAINS);
                this.emptyCart();
                this.couponDisable = false;
                this.couponErrMessage = null;
                this.couponTxt = null;
            });
    };

    @action checkAccountExistInfo = (isMobileKioskOrder) => {
        if (!this.isInternationalPhoneHubCode()) {
            this.phoneValidationResult = phoneNumberValidator.validate(this.phoneNumber);
        }

        const number = parseMobile(this.getPhoneNumber(isMobileKioskOrder));

        const isValidNumber = number && number.isValid();

        if (!isValidNumber) {
            this.phoneValidationMessage = "This doesn't look like a valid number. Please try again";
        } else if (isValidNumber) {
            this.phoneValidationMessage = '';

            this.loadingPhoneNoExist = true;
            const phoneNo = this.getPhoneNumber(isMobileKioskOrder);
            services.api
                .GetAccountExistInfo({ phoneNo, bearerToken: this.bearerToken })
                .then((response) => {
                    if (response) {
                        //customer name already exists in our system. order complete.
                        // const isNewUser = false;
                        const eligibleForLuckyWheel = response.eligible_for_lucky_wheel;
                        this.eligibleForLuckyWheelAfterTableNum = eligibleForLuckyWheel;
                        this.customerName = response.alias;
                        // if (this.orderType === this.ORDER_TYPES.DINE_IN) {
                        //     //show table number popup
                        //     this.setShowPopup(this.POPUP_TABLE_NUMBER);
                        // } else
                        if (isMobileKioskOrder && this.orderType === this.ORDER_TYPES.TAKE_AWAY) {
                            this.showMobileScreen = this.mobileScreens.ORDER_CONFIRMATION;
                        } else if (this.walletStatus && this.orderType === this.ORDER_TYPES.TAKE_AWAY) {
                            this.setShowPopup(this.POPUP_PAYMENT_OPTION);
                        } else {
                            if (
                                terminalCartStore.totalPrice != 0 &&
                                !isMobileKioskOrder &&
                                this.walletStatus
                            ) {
                                this.setShowPopup(this.POPUP_PAYMENT_OPTION);
                            } else {
                                const isNewUser = this.askForName;
                                const eligibleForLuckyWheel = this.eligibleForLuckyWheelAfterTableNum;
                                this.confirmOrder(isMobileKioskOrder, isNewUser, eligibleForLuckyWheel);
                            }
                        }
                    } else {
                        this.askForName = true;
                    }
                })
                .catch((response) => {
                    this.askForName = true;
                })
                .finally(() => {
                    this.loadingPhoneNoExist = false;
                });
        } else {
            this.phoneValidationMessage = '';
        }
    };

    @action checkPaymentStatus = () => {
        services.api
            .CheckPaymentStatus({
                orderNumber: this.orderNumber,
            })
            .then((response) => {
                if (response.status == 'Complete' && !this.paymentStatus) {
                    this.paymentStatus = true;
                }
            })
            .catch(() => {});
    };

    @action checkCoupon = (code) => {
        services.api
            .CheckCouponValidity({
                code: code,
            })
            .then((response) => {
                if (response.menu_item_id) {
                    const couponItems = this.itemsFetcher.data.filter((o) => {
                        return o.menu_item_id == response.menu_item_id;
                    });

                    if (!couponItems.length) {
                        this.couponErrMessage = 'Item is currently out of stock';
                    } else {
                        terminalCartStore.setFreeMenuId(couponItems[0].menu_item_id);

                        terminalCartStore.addToCart(couponItems[0]);

                        this.couponDisable = true;
                        this.couponErrMessage = null;
                        terminalCartStore.getTotalPrice();
                    }
                } else {
                    this.couponDisable = true;
                    this.couponErrMessage = null;

                    terminalCartStore.getTotalPrice();
                }
            })
            .catch((response) => {
                this.couponErrMessage = response.message;
            });
    };

    @action refreshQRCode = () => {
        return services.api
            .RefreshQRCode({
                orderNumber: this.orderNumber,
                bearerToken: this.bearerToken,
            });
    };

    @action sendOTP = (pin) => {
        const isMobileKioskOrder = false;

        const requestBody = {
            phone_no: this.getPhoneNumber(false),
            otp: pin,
        };
        services.api
            .SendOTP({
                body: requestBody,
            })
            .then((response) => {
                this.otpToken = response.token;
                this.pinValidation = false;
                this.checkAccountExistInfo(isMobileKioskOrder);
            })
            .catch((response) => {
                this.otpValidationMessage = response.message;
                // this.otpValidationMessage
            });
    };

    @action confirmOrder = (isMobileKioskOrder, isNewUser, eligibleForLuckyWheel, outletName, deviceId) => {
        const requestBody = this.getRequestPayloadforPosOrder(isMobileKioskOrder, outletName, deviceId);
        services.api.SendLogToBackend({
            log: `outlet=${outletName || ''} device_id=${
                deviceId || ''
            } request body to send to backend request=${JSON.stringify(requestBody)}`,
        });

        this.showingPopup = this.POPUP_ORDERING;
        services.api
            .PlaceTerminalOrderViaPos({
                requestBody,
                bearerToken: this.bearerToken,
                isMobileKioskOrder,
            })
            .then((response) => {
                this.orderInfo = MAYBE.Some(response);
                this.customerId = response.customer_id;

                if (isMobileKioskOrder) {
                    this.showMobileScreen = this.mobileScreens.ORDER_CONFIRMATION;
                } else {
                    this.magicSignInLink = response.magic_sign_in_link;
                    if (
                        this.addonsToShowOnLuckyWheel &&
                        this.addonsToShowOnLuckyWheel.length &&
                        eligibleForLuckyWheel &&
                        this.paymentType == 'Pos'
                    ) {
                        //only trigger lucky wheel flow if there are addons to show on lucky wheel are present and user hasn't placed an order on app yet or it's new user
                        if (this.addonsToShowOnLuckyWheel.length === 1) {
                            // if there's only one addon, skip the wheel and only show final screen
                            this.luckyWheelReward = this.addonsToShowOnLuckyWheel[0];
                            this.showLuckyWheelScreen = true;
                            this.showLuckyWheel = false;
                            this.rewardRedeemCode(this.luckyWheelReward.sku);
                        } else {
                            this.showLuckyWheelScreen = true;
                            this.showLuckyWheel = true;
                        }
                    } else if (this.paymentType == 'Kiosk_Ewallet') {
                        this.qrCodeText = response.qr_code;
                        this.orderNumber = response.order_number;
                        if (this.qrCodeText === 'Error') {
                            this.showingPopup = this.POPUP_PAYMENT_OPTION;
                            this.backBtn = true;
                        } else {
                            this.showingPopup = this.POPUP_QRCODE;
                        }
                    } else if (this.paymentType == 'Card') {
                        this.orderNumber = response.order_number;
                        this.pendingAmount = response.pending_amount;
                        this.showingPopup = this.POPUP_TAP_CARD;
                    } else {
                        this.showingPopup = this.POPUP_CONFIRM_ORDER;
                    }
                }
            })
            .catch((response) => {
                services.api.SendLogToBackend({
                    log: `outlet=${outletName || ''} device_id=${
                        deviceId || ''
                    } error confirming order with backend exception=${JSON.stringify(response)}`,
                });
                this.showingPopup = '';
                this.refreshMenu();
                this.emptyCart();
            });
    };

    @action removeOldItems() {
        terminalCartStore.filter((item) => item.menu_date === this.menuDate);
    }

    @action emptyCart() {
        terminalCartStore.itemsAdded = [];
        terminalCartStore.isCutleryProvided = false;
        services.api.UserCart.clear();
    }

    @action setOrderType(order_type) {
        this.orderType = order_type;
    }

    @action isInternationalPhoneHubCode() {
        return this.INTERNATIONAL_PHONE_HUB_CODES.indexOf(this.hubCode) > -1;
    }

    @action getPhoneNumber(isMobileKioskOrder) {
        if (this.isInternationalPhoneHubCode() && !isMobileKioskOrder) {
            if (this.isSkipClicked) {
                return '';
            }

            return this.rawPhoneNumber;
        }
        return '+' + this.phoneNumber.countryCode + this.phoneNumber.number;

        // return '+' + '123' + this.phoneNumber.number;
    }

    getRequestPayloadforPosOrder(isMobileKioskOrder, outletName, deviceId) {
        const items = terminalCartStore.getSnapshot();
        const cartServices = new CartServices(items);
        const orderType = this.orderType || 'DINE_IN';
        try {
            services.api.SendLogToBackend({
                log: `outlet=${outletName || ''} device_id=${deviceId || ''} getRequestPayloadforPosOrder
            request=${JSON.stringify(items)}
            currency=${services.getParam('currencyCode') || ''}
            customer_phone_no=${this.getPhoneNumber(isMobileKioskOrder)}
            menu_item=${JSON.stringify(cartServices.createPosOrders({ items }) || '')}
            table_number=${parseInt(this.tableNumber) || ''}
            `,
            });
        } catch (e) {
            services.api.SendLogToBackend({
                log: `outlet=${outletName || ''} device_id=${
                    deviceId || ''
                } getRequestPayloadforPosOrder items=${items} exception=${e}`,
            });
        }
        return {
            invoice_number: 'terminal',
            payment_method: this.paymentType,
            currency: services.getParam('currencyCode'),
            vendor_order_id: 'Pos',
            amount_paid: 0, // Backend sets this if vendor_order_id is Pos
            customer_phone_no: this.getPhoneNumber(isMobileKioskOrder),
            menu_item: cartServices.createPosOrders({ items }),
            pick_up_type: orderType,
            reward_code: this.couponDisable ? this.couponTxt : '',
            remark: '',
            token: this.otpToken,
            transaction_date_time: Date.now(),
            customer_name: this.customerName,
            table_number: parseInt(this.tableNumber),
            provide_cutlery: terminalCartStore.isCutleryProvided,
        };
    }

    getAddonByCategory(category) {
        if (RESOURCE.Success.is(this.addonsFetcher)) {
            return this.addonsFetcher.data.filter((item) => item.addon_category === category);
        }

        return [];
    }

    getAddonById(id) {
        if (RESOURCE.Success.is(this.addonsFetcher)) {
            const addOn = this.addonsFetcher.data.find((item) => item.today_menu_id === id);
            return addOn;
        }

        return null;
    }

    forceScrollToAddon() {
        this.onNeededToShowAddon$.push();

        when(
            () => RESOURCE.Success.is(this.addonsFetcher),
            () => {
                this.onNeededToShowAddon$.push();
            }
        );
    }

    handleCardPaymentResult = (e) => {
        if (e.detail.status) {
            console.log('Payment Completed');
            this.backBtn = false;
            this.showingPopup = this.POPUP_PAYMENT_CONFIRM;
            this.pendingAmount = null;
        } else {
            this.showPaymentFailurePopup();
        }
    }
    
    showPaymentFailurePopup = () => {
        this.showingPopup = this.POPUP_PAYMENT_FAILURE;
        this.backBtn = true;
    }

    handleCardReaderStatusUpdate = (e) => {
        if (e.detail.status) {
            this.cardReaderStatus = true;
            this.stopCardReaderStatusQuery();
        } else {
            this.cardReaderStatus = false;
            this.initCardReaderStatusCheck();
        }
    }

    initCardReaderStatusCheck = () => {
        const isEnabled = this.getCardProvider() == 'stripe';
        if (!isEnabled) {
            return;
        }
        if (this.cardReaderCheckInterval) {
            return;
        }

        console.log('Adding card reader check interval')
        this.cardReaderCheckInterval = setInterval(() => {
            this.triggerCardReaderStatusQuery();
        }, 10000);
    };

    triggerCardReaderStatusQuery = () => {
        const isEnabled = this.getCardProvider() == 'stripe';
        if (!isEnabled) {
            return;
        }

        console.log('Querying');
        const locationId = localStorage.getItem('stripeLocationId');
        if (window.webkit && window.webkit.messageHandlers && locationId) {
            window.webkit.messageHandlers.iosListener.postMessage({action: 'getCardReaderStatus', locationId: '' + locationId});
        }
    };

    stopCardReaderStatusQuery = () => {
        if (!this.cardReaderCheckInterval) {
            return;
        }
        clearInterval(this.cardReaderCheckInterval);
        this.cardReaderCheckInterval = null;
    }

    @action getPendingOrderTransaction = (callback) => {
        const requestBody = {
            order_number: this.orderNumber,
            new_payment_method: this.paymentType
        }
        services.api
            .GetPendingOrderTransaction({
                requestBody,
                bearerToken: this.bearerToken,
            })
            .then((response) => {
                this.pendingAmount = response.pending_amount;
                this.qrCodeText = response.qr_code
                callback();
            })
            .catch((response) => {
                callback();
            });
    };

    @action cancelReaderAction = () => {
        if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.iosListener.postMessage({action: 'cancelAction'});
        }
    };
}

const store = new TerminalMenuStore();
export default store;
